<template>
  <!-- tab内容 -->
  <div class="resolutionReviewmain">
    <!-- 条件查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
    <div class="line"></div>
    <!-- 表格数据 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true
      }"
      :key="activeNames"
      :dataSource.sync="tableData"
      ref="tableData"
      :api="api"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :getApi="activeNames == 1?'getPlantodo': 'getPlandoto'"
      :webPage="false">
      <template slot="code" slot-scope="scope">
        {{scope.$index+1}}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton @click="audit(scope.row, 'E')" content="审核" v-if="activeNames ==='1'" icon="iconfont iconshenhe1"></IconButton>
        <IconButton  content="查看"  @click="audit(scope.row, 'V')" v-if="activeNames !=='1'" icon="iconfont iconchakan"></IconButton>
      </template>
    </base-table>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseForm from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { factorApi, progressApi } from '@/api/businessApi'
export default {
  name: 'resolutionReviewmain',
  components: { baseForm, BaseTable, IconButton },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      tableData: [],
      loadCount: 0
    }
  },
  props: {
    activeNames: String,
    gysData: Array
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return factorApi
    }
  },
  watch: {
    activeNames: {
      handler () {
        this.clearParams()
        this.handleFilter()
      },
      deep: true
    }
  },
  mounted () {
    this.clearParams()
    this.handleFilter()
  },
  activated () {
    this.handleFilter()
  },
  methods: {
    handleFilter () {
      this.$nextTick(() => {
        this.loadCount++
      })
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        processStatus: 'FINANCING_RESULT'
      }
      if (this.activeNames === '2') {
        this.queryParas.reviewStatus = '10'
      } else if (this.activeNames === '3') {
        this.queryParas.reviewStatus = '20'
      }
    },
    // 审核
    audit (row, type) {
      sessionStorage.setItem('examine', JSON.stringify(row))
      sessionStorage.setItem('activeNames', this.activeNames)
      if (type === 'E') {
        const params = {
          businessId: row.bizId,
          instanceId: row.instanceId,
          taskId: row.taskId
        }
        progressApi.updatestatus(params).then(res => {
          if (res.success) {
            this.$router.push({
              path: '/business/resolutionReview/audit',
              query: { type: type }
            })
          }
        })
      } else {
        this.$router.push({
          path: '/business/resolutionReview/audit',
          query: { type: type }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.line{
height: 10px;
background: #F8F8F8;
}
</style>
